// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import "bootstrap";
import "../stylesheets/application.scss";

require('flatpickr')
require('flatpickr/dist/l10n/ja')

import flatpickr from "flatpickr";
flatpickr.localize(flatpickr.l10ns.ja)

document.addEventListener("turbolinks:load", () => {
    flatpickr(".flatpickr", {
        // option
        parseDate: function(dateText, dateFormat) {
            var date = new Date(dateText)
            if (isNaN(date)) {
                if (dateText.length == 8) {
                    var yyyy = dateText.substring(0, 4)
                    var mm = dateText.substring(4, 6) - 1
                    var dd = dateText.substring(6, 8)
                    date = new Date(yyyy, mm, dd)
                }
            }
            return date
        },
        allowInput: true,
    })
})

require("../stylesheets/application.scss")

import Chart from "chart.js/auto";
global.Chart = Chart;

//import ChartDataLabels from 'chartjs-plugin-datalabels';
//global.ChartDataLabels = ChartDataLabels;
//Chart.register(ChartDataLabels);

//require('chartjs-chart-box-and-violin-plot')
import { BoxPlotChart } from '@sgratzl/chartjs-chart-boxplot';
global.BoxPlotChart = BoxPlotChart;
